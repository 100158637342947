
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  nextTick,
  getCurrentInstance,
  watchEffect
} from "vue";
import { useStore } from "vuex";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import QuotesService from "@/core/services/car/QuotesService";
import CustomerService from "@/core/services/car/CustomerService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import Multiselect from "@vueform/multiselect";
import UserService from "@/core/services/UserService";
import MasterService from "@/core/services/car/MasterService";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  notif,
  yearList,
  getCurrentDate,
  validation,
  phoneNumberValidation,
  carBikeValueCheck,
  otherGenders 
} from "@/store/stateless/store";
import { ElNotification } from "element-plus";
import ExistCustomer from "@/components/modals/forms/ExistCustomer.vue";
import { setBodyPositionFixed } from "@/store/stateless/store";
import router from "@/router";
import moment from "moment";
import { registration } from '@/store/stateless/store'

interface addDetailUserParam {
  name: string;
  email: string;
  phone_country_code: string;
  phone_number: string;
  car_year: any | null;
  manufacturer_id: any | null;
  model_id: any | null;
  trim_level_id: any | null;
  is_new: number | null;
  car_value: number;
  first_registration_date: string;
  registration_emirate: number | any;
  dob: string | null;
  nationality: number | null;
  first_driving_license_country: number | null;
  driving_experience: any | null;
  uae_driving_experience: any | null;
  policy_start_date: string | null;
  claimed_insurance: number;
  no_claim_certificate: number | null;
  claims: number | null;
  gcc_specification: number | null;
  personal_use: number | null;
  current_policy_active: number | null;
  fully_comprehensive: number | null;
  third_party_liability: number | null;
  existing_policy_expired: number | null;
  driver_name: string;
  agent: string;
  gender: any | null;
  is_car: number;
  lead_source: string;
  is_vintage: number;
}
export default defineComponent({
  name: "customer-add-detail",
  props: {},
  components: {
    // Multiselect,
    ExistCustomer,
  },

  data() {
    return {
      dob: "",
      policyBegin: "",
      registrationDate: "",
    };
  },
  created() {
    const datenow = new Date();
    const day = datenow.getDate();
    const month = datenow.getUTCMonth();
    const year = datenow.getFullYear() - 18;
    const yearMinuseighteen = new Date(year, month, day);
    console.log(yearMinuseighteen);
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const inst = getCurrentInstance();
    let name = ref<string>("");
    let email = ref<string>("");

    // let phone_number = ref<string>("");
    let phone_country_code = ref<string>("");

    let addDetailUserParam = ref<addDetailUserParam>({
      name: "",
      email: "",
      phone_country_code: "+961",
      phone_number: "",
      car_year: 0,
      manufacturer_id: 0,
      model_id: 0,
      trim_level_id: -1,
      is_new: 2,
      car_value: 0,
      first_registration_date: "",
      registration_emirate: 7,
      dob: null,
      nationality: null,
      first_driving_license_country: null,
      driving_experience: "4",
      uae_driving_experience: "4",
      policy_start_date: getCurrentDate.today(),
      claimed_insurance: 2,
      no_claim_certificate: 1,
      claims: null,
      gcc_specification: 1,
      personal_use: 1,
      current_policy_active: 1,
      fully_comprehensive: 1,
      third_party_liability: 2,
      existing_policy_expired: 2,
      driver_name: "",
      agent: "",
      gender: null,
      is_car: 1,
      lead_source: "skye",
      is_vintage: 0,
    });
    // console.log(route.params);

    let nameRef = ref<any>("");
    let phoneRef = ref<any>("");
    let emailRef = ref<any>("");
    let yearRef = ref<any>("");
    let makeRef = ref<any>("");
    let modelRef = ref<any>("");
    let trimRef = ref<any>("");
    let carValueRef = ref<any>("");
    let registrationRef = ref<any>("");
    let DobRef = ref<any>("");
    let nationalityRef = ref<any>("");
    let dlRef = ref<any>("");
    let uaeDlRef = ref<any>("");
    let intDlRef = ref<any>("");
    nameRef.value = document.querySelector("#nameRef");
    phoneRef.value = document.querySelector("#phoneRef");
    yearRef.value = document.querySelector("#yearRef");
    
    DobRef.value = document.querySelector("#DobRef");
    // const year = new Date().getFullYear();
    // let yearsList = computed(() => {
    //   return Array.from(
    //     { length: year - 1900 },
    //     (value, index) => 2021 - index
    //   );
    // });
    // const years = ref({
    //   placeholder: "Please Select Car Year",
    //   value: null,
    //   options: yearList.getYearList(),
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    const years = yearList.getYearList();
    // let manufacturerList = computed(() => {
    //   return store.getters.getManufacturersList;
    // });

    let manufacturerList = computed(() => {
      let manu = store.getters.getManufacturersList;
      const exist = manu.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        manu.unshift({
          label: "Car Make",
          value: 0,
          id: 0,
          manufacturer: "Make",
        });
      } else {
        manu = manu.forEach((x) => {
          if (x.manufacturer != "QL") {
            return { ...x };
          }
        });
      }

      return manu;
    });
    // const manufacturers = ref({
    //   placeholder: "Please Select Car",
    //   value: null,
    //   options: manufacturerList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    // let modelList = computed(() => {
    //   return store.getters.getModelsList;
    // });
    let modelList = computed(() => {
      const model = store.getters.getModelsList;
      const exist = model.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        model.unshift({ label: "Car Model", value: 0, id: 0, model: "Model" });
      }
      return model;
    });

    const estimatedCarValue = computed(() => {
      return store.getters.getEstimatedCarValue
    })

    const getBrandNew = () => {
      addDetailUserParam.value.car_value = addDetailUserParam.value.is_new === 1 ? estimatedCarValue.value.new_min : estimatedCarValue.value.old_min;
    }
    // const models = ref({
    //   placeholder: "Please Select Car Model",
    //   value: null,
    //   options: modelList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    // let trimsList = computed(() => {
    //   return store.getters.getTrimDetails;
    // });
    let trimsList = computed(() => {
      const trim = store.getters.getTrimDetails;
      const exist = trim.some((e) => {
        return e.value == -1;
      });
      if (!exist) {
        trim.unshift({ label: "Trim", value: 0, id: 0, model: "Trim" });
      }
      return trim;
    });
    // const trims = ref({
    //   placeholder: "Please Select Car Model",
    //   value: null,
    //   options: trimsList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    let countryList = computed(() => {
      return store.getters.getCountry;
    });
    // const nationality = ref({
    //   placeholder: "Please Select Nationality",
    //   value: null,
    //   options: countryList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    let dlCountryList = computed(() => {
      return store.getters.getDlCountry;
    });

    // const dlCountry = ref({
    //   placeholder: "Please Select Country",
    //   value: null,
    //   options: dlCountryList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    const emirates = ref([
      { value: 0, label: "Abu Dhabi" },
      { value: 1, label: "Ajman" },
      { value: 2, label: "Dubai" },
      { value: 3, label: "Fujairah" },
      { value: 4, label: "Ras Al Khaimah" },
      { value: 5, label: "Sharjah" },
      { value: 6, label: "Umm Al Quwain" },
    ]);
    // const emirates = ref({
    //   placeholder: "Please Select Emirate",
    //   value: null,
    //   options: [
    //     { value: "1", label: "Abu Dhabi" },
    //     { value: "2", label: "Ajman" },
    //     { value: "3", label: "Dubai" },
    //     { value: "4", label: "Fujairah" },
    //     { value: "5", label: "Ras Al Khaimah" },
    //     { value: "6", label: "Sharjah" },
    //     { value: "7", label: "Umm Al Quwain" },
    //   ],
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    const uaeExperience = ref([
      { value: "1", label: "0-6 months" },
      { value: "2", label: "6 - 12 months" },
      { value: "3", label: "1 - 2 Years" },
      { value: "4", label: "More than 2 Years" },
    ]);
    const homeExperience = ref([
      { value: "1", label: "0-6 months" },
      { value: "2", label: "6 - 12 months" },
      { value: "3", label: "1 - 2 Years" },
      { value: "4", label: "More than 2 Years" },
    ]);
    const matchFirstDL = (e) => {
      rules.value.nationality.show = false
      addDetailUserParam.value.first_driving_license_country = e;
    };

    // const ncc = ref({
    //   placeholder: "Please Select",
    //   value: "1",
    //   options: [
    //     { value: 1, label: "No - I have no certificate" },
    //     { value: 2, label: "Yes - 1 year with proof" },
    //     { value: 3, label: "Yes - 2 years with proof" },
    //     { value: 4, label: "Yes - 3 or more years with proof" },
    //   ],
    //   searchable: true,
    //   clearOnSelect: true,
    // });
    const ncc = ref([
      { value: 1, label: "No - I have no certificate" },
      { value: 2, label: "Yes - 1 year with proof" },
      { value: 3, label: "Yes - 2 years with proof" },
      { value: 4, label: "Yes - 3 or more years with proof" },
    ]);
    let salesAgentList = computed(() => {
      return store.getters.getDomSalesAgentManagerTeamLeader;
    });
    // const salesAgent = ref({
    //   placeholder: "Please Select",
    //   value: null,
    //   options: salesAgentList,
    //   searchable: true,
    //   clearOnSelect: true,
    // });

    // let yearsList = ref<Array<any>>();

    const user = computed(() => {
      return JSON.parse(window.localStorage.getItem('user') || '')
    })

    onMounted(() => {
      getCarYears()
      setTimeout(() => {
        const phoneRef:any = inst?.refs.phoneRef
        phoneRef.focus()
      }, 500);
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Add User Details", ["Leads & Tasks"]);
      // QuotesService.getManufacturers();
      QuotesService.getCountries();
      UserService.getDomSalesAgentsManagerTeamLeader();
      MasterService.getCountryCodes();
      MasterService.getPolicyStartDateDays();
      // const carYears = yearList.getYearList();
      // yearsList.value = carYears.map(x => {
      //           return {
      //               label: x,
      //               value: x
      //           }
      //       })
      // if(yearsList.value !== undefined) {
      //   const exist = yearsList.value.some((e: any) => { return e.value === 0 });
      //       if(!exist) {
      //           yearsList.value.unshift({label: 'Car Year', value: 0});
      //       }
      // }
      if([2, 9, 13].includes(user.value.role_id)) {
        addDetailUserParam.value.agent = user.value.id
      }
    });
    const errors = computed(() => {
      return store.state.CustomerModule.errors;
    });

    const yearsList = computed(() => {

      let defaultValue = [{ label: 'Car Year', value: 0 }]
      
      const carYears = store.getters.getCarYears
      if(carYears.length > 0) {
        defaultValue = carYears.map(x => {
          return {
            label: x,
            value: x
          }
        })

        const exist = defaultValue.some(x => x.value === 0)
        if(!exist) defaultValue.unshift({ label: 'Car Year', value: 0 })

        return defaultValue
      }

      return defaultValue
    })

    const getCarYears = async () => {
      await MasterService.getCarYears({is_vintage: addDetailUserParam.value.is_vintage})
      addDetailUserParam.value.car_year = 0
      addDetailUserParam.value.manufacturer_id = 0
      addDetailUserParam.value.model_id = 0
      addDetailUserParam.value.trim_level_id = -1
      addDetailUserParam.value.car_value = 0
      addDetailUserParam.value.first_registration_date = ''

      QuotesService.resetValues()
    }

    // change event
    const onYearSelect = (event) => {
      // manufacturers.value.value = null;
      // models.value.value = null;
      // trims.value.value = null;
      QuotesService.resetValues();
      addDetailUserParam.value.car_value = 0;
      addDetailUserParam.value.manufacturer_id = 0;
      addDetailUserParam.value.model_id = 0;
      addDetailUserParam.value.trim_level_id = -1;
      // if(!yearList.getYearValue().includes(addDetailUserParam.value.car_year)) {
        addDetailUserParam.value.is_new = 2;
        addDetailUserParam.value.first_registration_date = '';
      // }
      // addDetailUserParam.value.car_year = event;
      if(!addDetailUserParam.value.is_vintage) {
        addDetailUserParam.value.first_registration_date = firstRegistrationDate(event)
      }
      QuotesService.getManufacturers({
        year: addDetailUserParam.value.car_year,
        is_vintage: addDetailUserParam.value.is_vintage,
        is_show_bike:false,
        is_add_driver:true,
      });
    };

    const firstRegistrationDate = (carYear) => {
      const currentDate = new Date()
      const cMonth = currentDate.getMonth() + 1
      const cDay = currentDate.getDate()
      const mMonth = cMonth.toString().length === 1 ? `0${cMonth}` : cMonth
      const mDay = cDay.toString().length === 1 ? `0${cDay}` : cDay
      // return `${mDay}/${mMonth}/${carYear}`
      return `${carYear}-${mMonth}-${mDay}`
    }

    const onManufacturerChange = () => {
      // addDetailUserParam.value.manufacturer_id = event;
      QuotesService.resetModelValues();
      addDetailUserParam.value.car_value = 0;
      // addDetailUserParam.value.manufacturer_id = 0;
      addDetailUserParam.value.model_id = 0;
      addDetailUserParam.value.trim_level_id = -1;
      QuotesService.getModels({
        manufacturer_id: addDetailUserParam.value.manufacturer_id,
        year: addDetailUserParam.value.car_year,
        is_vintage: addDetailUserParam.value.is_vintage,
        is_add_driver:true,
      });
      // models.value.value = null;
      // trims.value.value = null;
      addDetailUserParam.value.car_value = 0;
    };
    const onModelChange = (event) => {
      addDetailUserParam.value.car_value = 0;
      // addDetailUserParam.value.manufacturer_id = 0;
      // addDetailUserParam.value.model_id = 0;
      addDetailUserParam.value.trim_level_id = -1;
      QuotesService.resetTrimValues();
      // addDetailUserParam.value.model_id = event;
      QuotesService.getTrims({
        year: addDetailUserParam.value.car_year,
        model_id: addDetailUserParam.value.model_id,
        is_vintage: addDetailUserParam.value.is_vintage,
        is_add_driver:true,
      }).then((val:any) => {
        if(val) {
          addDetailUserParam.value.trim_level_id = val?.value
        }
      });
      // trims.value.value = null;
      addDetailUserParam.value.car_value = 0;
    };
    const onTrimChange = async (event) => {
      // addDetailUserParam.value.trim_level_id = event;
      // QuotesService.getEstimatedValue({
      //   trim_level_id: addDetailUserParam.value.trim_level_id,
      //   is_vintage: addDetailUserParam.value.is_vintage
      // }).then((result: any) => {
      //   addDetailUserParam.value.car_value = result.data
      //     ? parseFloat(result.data.value.old_min)
      //     : 0;
      // });
    };

    const getPolicyExpiry = (event) => {
      addDetailUserParam.value.existing_policy_expired = parseInt(
        event.target.value
      );
      addDetailUserParam.value.current_policy_active =
        event.target.value == 2 ? 1 : 2;
    };

    const getThirdParty = (event) => {
      addDetailUserParam.value.third_party_liability = parseInt(
        event.target.value
      );
      addDetailUserParam.value.fully_comprehensive =
        event.target.value == 1 ? 2 : 1;
    };
    // end here

    const reset = () => {
      // manufacturers.value.value = null;
      // models.value.value = null;
      // trims.value.value = null;
      addDetailUserParam.value.car_value = 0;
      addDetailUserParam.value.manufacturer_id = 0;
      addDetailUserParam.value.model_id = 0;
      addDetailUserParam.value.trim_level_id = -1;
      addDetailUserParam.value.car_year = 0;
    };

    const carValue = ref<string>("");
    const noOfClaims = ref<string>("");
    const loading = ref<boolean>(false);
    const addUserDetails = () => {
      resetValidation();

      isFormValid.value = true;
      isDecimalValid.value = true;
      loading.value = true;
      // addDetailUserParam.value.driver_name = addDetailUserParam.value.name;
      validatedUserDetails();

      if (isFormValid.value) {
        CustomerService.addUserDetails(addDetailUserParam.value)
          .then((result: any) => {

            notif.simple("Customer Added", "success", "Customer has been added successfully!");
            reset();
            setTimeout(() => {
              router.push({ name: "customer-details", params: { customerId: result.data.customer_id } });
              
            }, 2000);
            setTimeout(() => {
              loading.value = false;
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
            // notif.simple('Customer', 'warning', 'error!')
            loading.value = false;
          });
      } else {
        loading.value = false;
        focusField()
        // scrollThePage();
      }
    };
    const scrollThePage = () => {
      if (rules.value.name.show) {
        window.scrollTo(
          nameRef.value.getBoundingClientRect().x,
          nameRef.value.getBoundingClientRect().y
        );
      } else if (rules.value.phone_number.show) {
        window.scrollTo(
          nameRef.value.getBoundingClientRect().x,
          nameRef.value.getBoundingClientRect().y
        );
      } else if (rules.value.email.show) {
        window.scrollTo(
          nameRef.value.getBoundingClientRect().x,
          nameRef.value.getBoundingClientRect().y
        );
      } else if (
        rules.value.car_year.show ||
        rules.value.manufacturer_id.show ||
        rules.value.model_id.show ||
        rules.value.trim_level_id.show ||
        rules.value.car_value.show ||
        rules.value.registration_emirate.show ||
        rules.value.first_registration_date.show ||
        rules.value.policy_start_date.show
      ) {
        window.scrollTo(
          yearRef.value.getBoundingClientRect().x,
          yearRef.value.getBoundingClientRect().y
        );
      } else if (
        rules.value.dob.show ||
        rules.value.nationality.show ||
        rules.value.first_driving_license_country.show ||
        rules.value.uae_driving_experience.show ||
        rules.value.claimed_insurance.show ||
        rules.value.claims.show ||
        rules.value.no_claim_certificate.show ||
        rules.value.gender.show ||
        rules.value.driver_name.show
      ) {
        window.scrollTo(0, 450);
      }
    };
    const isDecimalValid = ref(true);
    let isFormValid = ref<boolean>(true);
    const validatedUserDetails = () => {
      let singleCount = addDetailUserParam.value.name.match(/[']/g);
      let minusCount = addDetailUserParam.value.name.match(/[-]/g);
      let decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;

      let singleCountChecker = true;
      let minusCountChecker = true;

      if (singleCount) {
        singleCountChecker = singleCount.length > 1 ? false : true;
      }
      if (minusCount) {
        minusCountChecker = minusCount.length > 1 ? false : true;
      }

      if (!singleCountChecker || !minusCountChecker) {
        rules.value.name.message = `Only character a-z, - and ' allowed`;
        rules.value.name.show = true;
      }

      if (!decimalPlaces.test(String(addDetailUserParam.value.car_value))) {
        rules.value.car_value.message =
          "Please enter upto 2 decimal places only";
        rules.value.car_value.show = true;
        isDecimalValid.value = false;
      }

      if (
        addDetailUserParam.value.phone_number &&
        (addDetailUserParam.value.phone_number.length < phoneNumberValidation.setLimit(addDetailUserParam.value.phone_country_code,'min') ||
          addDetailUserParam.value.phone_number.length > phoneNumberValidation.setLimit(addDetailUserParam.value.phone_country_code,'max'))
      ) {
        rules.value.phone_number.message = `Phone number must not be less than ${phoneNumberValidation.setLimit(addDetailUserParam.value.phone_country_code,'min')} digits`;
        rules.value.phone_number.show = true;
      }

      if (
        addDetailUserParam.value.name == null ||
        addDetailUserParam.value.name == "" ||
        addDetailUserParam.value.name.length < 3
      ) {
        if (
          addDetailUserParam.value.name &&
          addDetailUserParam.value.name.length < 3
        ) {
          rules.value.name.message = "Name must not be less than 3 characters";
        } else {
          rules.value.name.message = "Please enter full name";
        }
        rules.value.name.show = true;
        isFormValid.value = false;
      }
      // if(addDetailUserParam.value.name.length < 4) {
      //   rules.value.name.message = 'Driver name must be atleast 3 characters';
      //   rules.value.name.show =true
      //   isFormValid.value = false
      // }
      if (addDetailUserParam.value.phone_number == "") {
        rules.value.phone_number.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.phone_number != "" &&
        addDetailUserParam.value.phone_number.trim().length <= 4
      ) {
        rules.value.phone_number.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.email == null ||
        addDetailUserParam.value.email == ""
      ) {
        rules.value.email.show = true;
        isFormValid.value = false;
      } else if (
        addDetailUserParam.value.email != null &&
        addDetailUserParam.value.email != "" &&
        !validation.validateEmail(addDetailUserParam.value.email)
      ) {
        rules.value.email.message = "Please enter valid email address";
        rules.value.email.show = true;
        isFormValid.value = false;
      } else {
        rules.value.email.show = false;
      }

      if (
        addDetailUserParam.value.phone_country_code == null ||
        addDetailUserParam.value.phone_country_code == ""
      ) {
        rules.value.phone_country_code.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.car_year == null ||
        addDetailUserParam.value.car_year == 0
      ) {
        rules.value.car_year.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.manufacturer_id == null ||
        addDetailUserParam.value.manufacturer_id == 0
      ) {
        rules.value.manufacturer_id.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.model_id == null ||
        addDetailUserParam.value.model_id == 0
      ) {
        rules.value.model_id.show = true;
        isFormValid.value = false;
      }
      // if (
      //   addDetailUserParam.value.trim_level_id == null ||
      //   addDetailUserParam.value.trim_level_id == 0
      // ) {
      //   rules.value.trim_level_id.show = true;
      //   isFormValid.value = false;
      // }
      
      if (
        addDetailUserParam.value.car_value == null ||
        addDetailUserParam.value.car_value == 0
      ) {
        rules.value.car_value.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.registration_emirate == null
      ) {
        rules.value.registration_emirate.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.first_registration_date == null ||
        addDetailUserParam.value.first_registration_date == ""
      ) {
        rules.value.first_registration_date.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.policy_start_date == null ||
        addDetailUserParam.value.policy_start_date == ""
      ) {
        rules.value.policy_start_date.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.dob == null ||
        addDetailUserParam.value.dob == ""
      ) {
        rules.value.dob.show = true;
        isFormValid.value = false;
      }
      if (
        addDetailUserParam.value.nationality == null ||
        addDetailUserParam.value.nationality == 0
      ) {
        rules.value.nationality.show = true;
        isFormValid.value = false;
      }
      if (addDetailUserParam.value.dob && checkdateLeadDriverDob.value < 18) {
        rules.value.dob.message = "Date of birth must not less than 18 years old";
        rules.value.dob.show = true;
        isFormValid.value = false;
      }
      // Hide for Lebanon
      // if (
      //   addDetailUserParam.value.first_driving_license_country == null ||
      //   addDetailUserParam.value.first_driving_license_country == 0
      // ) {
      //   rules.value.first_driving_license_country.show = true;
      //   isFormValid.value = false;
      // }
      if (
        addDetailUserParam.value.driving_experience == null ||
        addDetailUserParam.value.driving_experience == 0
      ) {
        rules.value.driving_experience.show = true;
        isFormValid.value = false;
      }

      if (
        addDetailUserParam.value.claimed_insurance == 1 &&
        (!addDetailUserParam.value.claims ||
          addDetailUserParam.value.claims < 0)
      ) {
        rules.value.claims.show = true;
        isFormValid.value = false;
      }
      // if (
      //   addDetailUserParam.value.claims == null ||
      //   (addDetailUserParam.value.claims == "" &&
      //     addDetailUserParam.value.claimed_insurance == 1)
      // ) {
      //   rules.value.claims.show = true;
      //   isFormValid.value = false;
      // }
      if (
        addDetailUserParam.value.no_claim_certificate == null &&
        addDetailUserParam.value.claimed_insurance == 2
      ) {
        rules.value.no_claim_certificate.show = true;
        isFormValid.value = false;
      }
      if (
        ![1, 7, 11].includes(user.value.role_id) && (addDetailUserParam.value.agent == null ||
        addDetailUserParam.value.agent == "")
      ) {
        rules.value.agent.show = true;
        isFormValid.value = false;
      }
      if (addDetailUserParam.value.is_new == null) {
        rules.value.is_new.show = true;
        isFormValid.value = false;
      }
      if (addDetailUserParam.value.existing_policy_expired == null) {
        rules.value.existing_policy_expired.show = true;
        isFormValid.value = false;
      }
      if (addDetailUserParam.value.gcc_specification == null) {
        rules.value.gcc_specification.show = true;
        isFormValid.value = false;
      }
      if (addDetailUserParam.value.personal_use == null) {
        rules.value.personal_use.show = true;
        isFormValid.value = false;
      }
      if (addDetailUserParam.value.third_party_liability == null) {
        rules.value.third_party_liability.show = true;
        isFormValid.value = false;
      }

      if (
        addDetailUserParam.value.policy_start_date == null ||
        addDetailUserParam.value.policy_start_date == ""
      ) {
        rules.value.policy_start_date.show = true;
        isFormValid.value = false;
      }
      
      if (
        !addDetailUserParam.value.driver_name ||
        (addDetailUserParam.value.driver_name &&
          addDetailUserParam.value.driver_name.length != 3)
      ) {
        if (
          addDetailUserParam.value.driver_name &&
          addDetailUserParam.value.driver_name.length < 3
        ) {
          rules.value.driver_name.message =
            "Driver name must not be less than 3 characters";
          rules.value.driver_name.show = true;
          isFormValid.value = false;
        }

        if (
          addDetailUserParam.value.driver_name == "" ||
          addDetailUserParam.value.driver_name == null
        ) {
          rules.value.driver_name.message = "Please enter driver name";
          rules.value.driver_name.show = true;
          isFormValid.value = false;
        }
      } else {
        rules.value.driver_name.show = false;
      }

      if (addDetailUserParam.value.gender == null) {
        rules.value.gender.show = true;
        isFormValid.value = false;
      }
    };
    const resetValidation = () => {
      rules.value.name.show = false;
      rules.value.model_id.show = false;
      rules.value.agent.show = false;
      rules.value.no_claim_certificate.show = false;
      rules.value.claims.show = false;
      rules.value.uae_driving_experience.show = false;
      rules.value.nationality.show = false;
      rules.value.driving_experience.show = false;
      rules.value.first_driving_license_country.show = false;
      rules.value.car_value.show = false;
      rules.value.dob.show = false;
      rules.value.policy_start_date.show = false;
      rules.value.first_registration_date.show = false;
      rules.value.registration_emirate.show = false;
      rules.value.trim_level_id.show = false;
      rules.value.phone_country_code.show = false;
      rules.value.manufacturer_id.show = false;
      rules.value.email.show = false;
      rules.value.phone_number.show = false;
      rules.value.car_year.show = false;
      rules.value.is_new.show = false;
      rules.value.existing_policy_expired.show = false;
      rules.value.personal_use.show = false;
      rules.value.third_party_liability.show = false;
      rules.value.gcc_specification.show = false;
    };

    const rules = ref({
      name: {
        show: false,
        message: "Please enter name",
      },
      email: {
        show: false,
        message: "Please enter email",
      },
      phone_number: {
        show: false,
        message: "Please enter phone number",
        length_message: "Phone number should be greater than 4 digits",
      },
      agent: {
        show: false,
        message: "Please select sales agent",
      },

      phone_country_code: {
        show: false,
        message: "Please select country code",
      },
      car_year: {
        show: false,
        message: "Please select car year",
      },
      manufacturer_id: {
        show: false,
        message: "Please select car make",
      },
      model_id: {
        show: false,
        message: "Please select car model",
      },
      trim_level_id: {
        show: false,
        message: "Please select car trim level",
      },
      car_value: {
        show: false,
        message: "Please enter car value ",
      },
      registration_emirate: {
        show: false,
        message: "Please select Registration country",
      },

      nationality: {
        show: false,
        message: "Please select the nationality",
      },
      dob: {
        show: false,
        message: "Please enter date of birth",
      },
      first_registration_date: {
        show: false,
        message: "Please enter first registration date",
      },
      first_driving_license_country: {
        show: false,
        message: "Please select the country",
      },
      uae_driving_experience: {
        show: false,
        message: "Please select the Experience",
      },
      driving_experience: {
        show: false,
        message: "Please select the Experience",
      },
      no_claim_certificate: {
        show: false,
        message: "Please select the No claims certificate",
      },
      claims: {
        show: false,
        message: "Please enter Claims.",
      },
      policy_start_date: {
        show: false,
        message: "Please select Policy Start Date",
      },
      driver_name: {
        show: false,
        message: "Please enter driver Name",
      },
      gender: {
        show: false,
        message: "Please select the gender ",
      },
      claimed_insurance: {
        show: false,
        message: "Please Insurance Claims",
      },
      policyType: {
        show: false,
        message: "Please Select policy type",
      },
      is_new: {
        show: false,
        message: "Please select car is brand new ",
      },
      existing_policy_expired: {
        show: false,
        message: "Please select policy active",
      },
      gcc_specification: {
        show: false,
        message: "Please select gcc specs & no modification",
      },
      personal_use: {
        show: false,
        message: "Please select vehicle use",
      },
      third_party_liability: {
        show: false,
        message: "Please select existing policy TPL only ",
      },
    });

    let fetchCarLeadObj = computed(() => {
      return store.getters.getCarLead;
    });
    const search = ref<string>("");


    let countryCodeList = computed(() => {
      return store.getters.getCountryCode;
    });

    // let countryCodes = ref({
    //   data: [],
    //   value: "+961",
    //   options: countryCodeList,
    //   searchable: true,
    //   createTag: false,
    // });
    const onCountryCodeChange = (event) => {
      addDetailUserParam.value.phone_country_code = event;
    };

    const validateFirstRegistration = computed(() => {
      return new Date(addDetailUserParam.value.first_registration_date);
    });

    const firstDate = ref("");

    watch(firstDate, (value) => {
      const result = value
        .replace(/^(\d\d)(\d)$/g, "$1-$2")
        .replace(/^(\d\d-\d\d)(\d+)$/g, "$1-$2")
        .replace(/[^\d-]/g, "");
      nextTick(() => (firstDate.value = result));
    });

    const addFirstRegDate = () => {
      addDetailUserParam.value.first_registration_date = firstDate.value;
    };

    const resetClaims = () => {
      if (addDetailUserParam.value.claimed_insurance == 1) {
        // addDetailUserParam.value.no_claim_certificate = 1;
        addDetailUserParam.value.claims = 1;
        // ncc.value.value = "";
        addDetailUserParam.value.no_claim_certificate = null;
      } else {
        addDetailUserParam.value.claims = null;
        addDetailUserParam.value.no_claim_certificate = 1;
        // ncc.value.value = "1";
      }
    };

    const yearMinuseighteen = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear() - 18;
      return new Date(year, month, day);
    });
    const yearMinusOne = computed(() => {
      const datenow = new Date();
      const day = datenow.getDate();
      const month = datenow.getUTCMonth();
      const year = datenow.getFullYear();
      return new Date(year, month, day);
    });
    const disabledDate = (time: any) => {
      return time.getTime() > new Date(yearMinuseighteen.value);
    };
    const disableDateOne = (time: any) => {
      return time.getTime() < new Date(yearMinusOne.value);
    };

    const getNoClaim = (event) => {
      addDetailUserParam.value.no_claim_certificate = event;
      console.log(addDetailUserParam.value);
    };
    const clearNoClaim = () => {
      addDetailUserParam.value.no_claim_certificate = null;
    };

    const updateDriverName = () => {
      rules.value.name.show = false;
      rules.value.driver_name.show = false;
      addDetailUserParam.value.driver_name = addDetailUserParam.value.name;
    };

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    });

    const checkPhoneNo = async() => {
      if (addDetailUserParam.value.phone_number) {
        const response = await CustomerService.checkExistNumber({
          phoneNo: addDetailUserParam.value.phone_number,
          country: addDetailUserParam.value.phone_country_code,
        });
        if(response.is_car == 1) store.commit('SET_EXIST_CUSTOMER', response)
        else {
            addDetailUserParam.value.name = response.name
            addDetailUserParam.value.driver_name = response.name
            addDetailUserParam.value.email = response.email
            addDetailUserParam.value.driver_name = response.name
        }
      }
    };

    const clearIt = () => {
      addDetailUserParam.value.phone_number = "";
      addDetailUserParam.value.name = "";
      addDetailUserParam.value.email = "";
      CustomerService.clearExistCustomer();
    };

    const redirectIt = (id: number, redirectType: string) => {
      let path = ''
      if(redirectType === 'customerDetails') path = 'customer-details'
      if(redirectType === 'addCar') path = 'new-car-quote'
      if(redirectType === 'addBike') path = 'customer-add-new-bike'
      if(redirectType === 'addCar') {
        router.push({ name: path, params: { driverDetailsId: id }, query: {leadsAndTasks: 'true'}})
      } else {
        router.push({ name: path, params: { customerId: id }, query: {leadsAndTasks: 'true'}})
      }
      clearIt()
    };

    const checkdateLeadDriverDob = computed(() => {
      return dateDif(addDetailUserParam.value.dob);
    });

    const dateDif = (date: any) => {
      if (date) {
        date = date.split("-");
        var today = new Date();
        var year = today.getFullYear();
        var month = today.getMonth() + 1;
        var day = today.getDate();
        var yy = parseInt(date[0]);
        var mm = parseInt(date[1]);
        var dd = parseInt(date[2]);
        var years, months, days;
        // months
        months = month - mm;
        if (day < dd) {
          months = months - 1;
        }
        // years
        years = year - yy;
        if (month * 100 + day < mm * 100 + dd) {
          years = years - 1;
          months = months + 12;
        }
        // days
        days = Math.floor(
          (today.getTime() -
            new Date(yy + years, mm + months - 1, dd).getTime()) /
            (24 * 60 * 60 * 1000)
        );
        //
        return years;
      }
      return 0;
    };
    const addfixedPosition = () => {
      setBodyPositionFixed.value.isFocused = true;
      // console.log("focused");
      document.body.classList.add("body-position-fixed");
    };
    const removeFixedPosition = () => {
      setBodyPositionFixed.value.isFocused = false;
      // console.log("removed");
      document.body.classList.remove("body-position-fixed");
    };

    const disableIsNew = computed(() => {
            if(yearList.getYearValue().includes(addDetailUserParam.value.car_year)) {
                return false;
            } else {
                return true;
            }
    })

    
    const currentyear = computed(() => {
      return new Date().getFullYear();
    })

    const dobYear = computed(() => {
      return currentyear.value - 18;
    })

    const currentDay = computed(() => {
      const today = new Date().getDate();
      return today > 9 ? today : '0'+today;
    })

    const currentMonth = computed(() => {
      const month = new Date().getMonth() + 1;
      return month > 9 ? month : '0'+month;
    })

    const carYearMin = computed(() => {
      const carYear = parseInt(addDetailUserParam.value.car_year);
      return carYear - 2;
    })

    const carYearMax = computed(() => {
      const carYear = parseInt(addDetailUserParam.value.car_year);
      return carYear + 1;
    })

    const amount = ref(0);

    const focusField = () => {
      const { name, phone_number, email, car_year, manufacturer_id, model_id, car_value, first_registration_date, policy_start_date, dob, nationality, first_driving_license_country, gender, driver_name, agent } = rules.value

      if(name.show) {
        const nameRef:any = inst?.refs.nameRef
        nameRef.focus()
        window.scrollTo(0, 0)
      }

      if(!name.show && phone_number.show) {
        const phoneRef:any = inst?.refs.phoneRef
        phoneRef.focus()
        window.scrollTo(0, 0)
      }

      if(!name.show && !phone_number.show && email.show) {
        const emailRef:any = inst?.refs.emailRef
        emailRef.focus()
        window.scrollTo(0, 0)
      }

      if(!name.show && !phone_number.show && !email.show && car_year.show) {
        const carYearRef:any = inst?.refs.carYearRef
        carYearRef.focus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          manufacturer_id.show) {
        const makeRef:any = inst?.refs.makeRef
        makeRef.focus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          model_id.show
          ) {
        const modelRef:any = inst?.refs.modelRef
        modelRef.focus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          car_value.show
          ) {
        const carValueRef:any = inst?.refs.carValueRef
        carValueRef.isFocus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          first_registration_date.show
          ) {
        const registrationRef:any = inst?.refs.registrationRef
        registrationRef.isFocus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          !first_registration_date.show && 
          policy_start_date.show
          ) {
        const expectedPolicyRef:any = inst?.refs.expectedPolicyRef
        expectedPolicyRef.isFocus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          !first_registration_date.show && 
          !policy_start_date.show &&
          dob.show
          ) {
        const dobRef:any = inst?.refs.dobRef
        dobRef.isFocus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          !first_registration_date.show && 
          !policy_start_date.show &&
          !dob.show && 
          nationality.show
          ) {
        const nationalityRef:any = inst?.refs.nationalityRef
        nationalityRef.focus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          !first_registration_date.show && 
          !policy_start_date.show &&
          !dob.show && 
          !nationality.show && 
          first_driving_license_country.show
          ) {
        const dlRef:any = inst?.refs.dlRef
        dlRef.focus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          !first_registration_date.show && 
          !policy_start_date.show &&
          !dob.show && 
          !nationality.show && 
          !first_driving_license_country.show &&
          gender.show
          ) {
        const genderRef:any = inst?.refs.genderRef
        genderRef.focus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          !first_registration_date.show && 
          !policy_start_date.show &&
          !dob.show && 
          !nationality.show && 
          !first_driving_license_country.show &&
          !gender.show && 
          driver_name.show
          ) {
        const driverNameRef:any = inst?.refs.driverNameRef
        driverNameRef.focus()
      }

      if(!name.show && 
          !phone_number.show && 
          !email.show && 
          !car_year.show &&
          !manufacturer_id.show &&
          !model_id.show &&
          !car_value.show &&
          !first_registration_date.show && 
          !policy_start_date.show &&
          !dob.show && 
          !nationality.show && 
          !first_driving_license_country.show &&
          !gender.show && 
          !driver_name.show &&
          agent.show
          ) {
        const agentRef:any = inst?.refs.agentRef
        agentRef.focus()
      }
    }

    const toExpectPolicyActiveDate = () => {
        const policyActiveRef:any = inst?.refs.policyActiveRef
        policyActiveRef.focus()
    }

    const toExpectPolicyStartDate = () => {
        const expectedPolicyRef:any = inst?.refs.expectedPolicyRef
        expectedPolicyRef.isFocus()
    }

    const toRegisrationEmirate = () => {
        const registrationEmirateRef:any = inst?.refs.registrationEmirateRef
        registrationEmirateRef.focus()
    }

    const toVehicleRegistraion = () => {
        const vehicleUseRef:any = inst?.refs.vehicleUseRef
        vehicleUseRef.focus()
    }

    const toRegistrationDateRef = () => {
        const registrationRef:any = inst?.refs.registrationRef
        if(!registrationRef.disabled) {
          registrationRef.isFocus()
        } else {
          toRegisrationEmirate()
        }
    }

    const toGccSpec = () => {
        const gccSpecRef:any = inst?.refs.gccSpecRef
        gccSpecRef.focus()
    }

    const toDriverNationality = () => {
        const nationalityRef:any = inst?.refs.nationalityRef
        nationalityRef.focus()
    }

    const toTPL = () => {
      const tplRef:any = inst?.refs.expectedPolicyRef
        tplRef.isFocus()
    }

    const toDob = () => {
      const dobRef:any = inst?.refs.dobRef
      dobRef.isFocus()
    }

    const toDL = () => {
      const dlRef:any = inst?.refs.dlRef
        dlRef.focus()
    }

    const isShow = computed(() => {
      if([1, 7, 11, 14, 15].includes(user.value.role_id)) return true

      return false
    })

    const getAddCustomerEmail = computed(() => {
      // return store.getters.getAddCustomerEmail
      return store.state.CustomerModule.addCustomerEmail
    })

    const getExistCustomer = computed(() => {
      return store.getters.getExistCustomer
    })

    const existingCustomer = ref()

    watchEffect(() => {
      if(getAddCustomerEmail.value) {
        const emailRef:any = inst?.refs.emailRef
        emailRef.focus()
        rules.value.email.message = 'Email address already exist!'
        rules.value.email.show = true
        loading.value = false
      }

      if(getExistCustomer.value) {
        existingCustomer.value.toFirst()
      }
    })

    const fixFigure = () => {
      addDetailUserParam.value.car_value = roundUp(parseFloat(addDetailUserParam.value.car_value.toString().replace(',', '')))
      if(isNaN(addDetailUserParam.value.car_value)) {
        addDetailUserParam.value.car_value = 0
      }
    }

    const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

    const policyRestrictDates = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrict
    })
    
    const policyRestrictDatesUpcoming = computed(() => {
      return store.getters.getPolicyStartDateXDaysRestrictUpcoming
    })

    const disabledDates = computed(() => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - policyRestrictDates.value)
      return moment(currentDate).format('YYYY-MM-DD')
    })

    const disableUpcomingDate = computed(() => {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + policyRestrictDatesUpcoming.value)
      return moment(currentDate).format('YYYY-MM-DD')
    })

    return {
      registration,
      disableUpcomingDate,
      disabledDates,
      getCarYears,
      fixFigure,
      existingCustomer,
      getExistCustomer,
      getAddCustomerEmail,
      user,
      isShow,
      toDL,
      toDob,
      toTPL,
      toRegistrationDateRef,
      toVehicleRegistraion,
      toDriverNationality,
      toGccSpec,
      toExpectPolicyActiveDate,
      toExpectPolicyStartDate,
      toRegisrationEmirate,
      focusField,
      amount,
      estimatedCarValue,
      getBrandNew,
      carYearMin,
      carYearMax,
      currentyear,
      currentDay,
      currentMonth,
      dobYear,
      disableIsNew,
      yearsList,
      setBodyPositionFixed,
      addfixedPosition,
      removeFixedPosition,
      trimsList,
      modelList,
      manufacturerList,
      checkdateLeadDriverDob,
      isDecimalValid,
      checkPhoneNo,
      clearIt,
      redirectIt,
      ElNotification,
      updateDriverName,
      getNoClaim,
      clearNoClaim,
      // errorMessage,
      // value,
      disableDateOne,
      yearMinusOne,
      yearMinuseighteen,
      addFirstRegDate,
      firstDate,
      validateFirstRegistration,
      loading,
      matchFirstDL,
      isFormValid,
      rules,
      errors,
      disabledDate,
      name,
      email,
      phone_country_code,
      noOfClaims,
      onManufacturerChange,
      addDetailUserParam,
      onModelChange,
      onYearSelect,
      onTrimChange,
      carValue,
      getPolicyExpiry,
      addUserDetails,
      search,
      years,
      // manufacturers,
      // models,
      // trims,
      emirates,
      // nationality,
      countryList,
      // dlCountry,
      dlCountryList,
      uaeExperience,
      homeExperience,
      ncc,
      // salesAgent,
      salesAgentList,
      // countryCodes,
      countryCodeList,
      onCountryCodeChange,
      getThirdParty,
      resetClaims,
      nameRef,
      phoneRef,
      yearRef,
      DobRef,
      phoneNumberValidation,
      carBikeValueCheck,
      otherGenders
      //   fetchCarLeadObj,
    };
  },
});
